<template>
  <div>
    <PpdbMarketSma></PpdbMarketSma>
  </div>
</template>

<script>

  import PpdbMarketSma from "@/components/ppdb/market/PpdbMarketSma.vue";

  export default {
    name: 'PendaftaranSmaView',

    components: {
      PpdbMarketSma,
    },
  }
</script>
